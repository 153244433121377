









































import Vue from 'vue';
import { Component, Prop, VModel, Watch } from 'vue-property-decorator';

interface Question {
  label: string;
  scale: string;
  id: number;
  prefix: string;
}

@Component({})
export default class QuestionEditDialog extends Vue {
  @Prop({ required: true }) readonly question!: Question;

  @VModel({ type: Boolean }) open!: boolean;

  @Watch('question')
  onQuestionChanged(val: Question): void {
    this.newQuestion = { ...val };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newQuestion: any = null;
  loading = false;

  get questionLabel(): string {
    return this.newQuestion?.label;
  }

  set questionLabel(label: string) {
    this.newQuestion.label = label;
  }

  get questionPrefix(): string {
    return this.newQuestion?.prefix;
  }

  set questionPrefix(prefix: string) {
    this.newQuestion.prefix = prefix;
  }

  async save(): Promise<void> {
    this.loading = true;
    await this.$services.questions.updateQuestion(this.newQuestion);
    setTimeout(() => {
      this.loading = false;
      this.open = false;
      this.$emit('updated');
    }, 1000);
  }
}
