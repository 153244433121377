import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/quizzes',

    component: () =>
      import('../../../layouts').then((module) => {
        const { AdminLayoutMain } = module;
        return AdminLayoutMain;
      }),
    children: [
      {
        path: '',
        name: 'quizzes.view',
        component: () =>
          import('../views').then((module) => {
            const { QuizzesView } = module;
            return QuizzesView;
          }),
      },
      {
        path: ':id',
        name: 'quiz.single',
        props: true,
        component: () =>
          import('../views').then((module) => {
            const { QuizViewSingle } = module;
            return QuizViewSingle;
          }),
      },
    ],
  },
];

export { routes };
