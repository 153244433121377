








import Vue from 'vue';
import Component from 'vue-class-component';
import QuizzesTable from '../components/QuizzesTable.vue';

@Component({
  components: {
    QuizzesTable,
  },
})
export default class QuizzesView extends Vue {}
