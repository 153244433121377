


































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ReportSectionSummary extends Vue {
  @Prop({ required: true }) readonly data!: any;

  bookingLink = 'https://needapsych.com';
  recommendations = [
    {
      heading: 'Stress and Skill',
      key: 'stress_skill',
      colour: 'white',
      message: '',
      help: false,
    },
    {
      heading: 'Mood and Thinking',
      key: 'mood_worry',
      colour: 'white',
      message: '',
      help: false,
    },
    {
      heading: 'Attachment to Others',
      key: 'avoidance_anxiety',
      colour: 'white',
      message: '',
      help: true,
    },
  ];

  mounted(): void {
    this.recommendations.forEach((value) => {
      value.colour = this.generateMessageData(value.key).colour;
      value.message = this.generateMessageData(value.key).string;
      value.help = value.message == 'Not at risk' ? false : true;
    });
  }

  getHelpString(): string {
    const needsHelp = this.recommendations.filter((value) => {
      return value.help == true;
    });

    let string = '';

    needsHelp.forEach((value, index) => {
      if (index == needsHelp.length - 1 && needsHelp.length > 1) {
        string = string.concat(' and ');
      }
      string = string.concat(value.heading);
      if (index != needsHelp.length - 1 && needsHelp.length > 2) {
        string = string.concat(', ');
      }
    });
    return string;
  }

  generateMessageData(pair: string): { string: string; colour: string } {
    const string = this.generateMessageDataString(pair);
    const identifier = pair.split('_');
    const colour = this.generateMessageDataColour(string, identifier[0]);

    return { string, colour };
  }

  generateMessageDataString(pair: string): string {
    const data = this.data.report[pair].scales as {
      result: any;
    };
    let string = 'Help with ';
    for (const [key, value] of Object.entries(data)) {
      let needsHelp;
      if (value.result.high && !value.result.reversed) {
        needsHelp = true;
      } else if (value.result.low && value.result.reversed) {
        needsHelp = true;
      }
      if (needsHelp) {
        if (string != 'Help with ') {
          string = string.concat(' and ');
        }
        string = string.concat(key);
      }
    }
    if (string == 'Help with ') {
      return 'Not at risk';
    }
    return string + ' advised.';
  }

  generateMessageDataColour(string: string, identifier: string): string {
    let colour = '';
    if (string == 'Not at risk') {
      colour = this.$vuetify.theme.currentTheme.graphGreen as string;
    } else if (string.includes('and')) {
      colour = this.$vuetify.theme.currentTheme.graphRed as string;
    } else if (string.includes(identifier)) {
      colour = this.$vuetify.theme.currentTheme.graphOrange as string;
    } else {
      colour = this.$vuetify.theme.currentTheme.graphYellow as string;
    }

    return colour;
  }
}
