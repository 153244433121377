import Vue from 'vue';
import Component from 'vue-class-component';
import { VApp, VAppBar, VContent, VTabs } from 'vuetify/lib';
import { GuestLayoutMain } from './domains/guest/modules/layouts/main';

@Component({
  components: {
    VApp,
    VAppBar,
    VContent,
    GuestLayoutMain,
    VTabs,
  },

  render(this: App) {
    return (
      <transition name='fade' mode='out-in'>
        <router-view></router-view>
      </transition>
    );
  },
})
export class App extends Vue {}
