










import Vue from 'vue';
import Component from 'vue-class-component';
import { LoginForm } from '../components';

@Component({
  components: {
    LoginForm,
  },
})
export default class LoginPage extends Vue {}
