/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { IServiceProvider, provider } from '../services';

export const prepareServices = (store: any): IServiceProvider => {
  store.$services = provider();
  Vue.mixin({
    beforeCreate() {
      this.$services = store.$services;
    },
  });

  return store.$services;
};
