




import { Vue, Component, Prop } from 'vue-property-decorator';
import { data } from './data';
import Graph from '../components/ReportGraphTest.vue';
import Report from '../components/Report.vue';

@Component({
  components: {
    Graph,
    Report,
  },
})
export default class ReportRenderer extends Vue {
  @Prop({ default: '' }) readonly url!: string;
  reportData: any = null;
  ready = false;
  async mounted(): Promise<void> {
    if (this.url == '') {
      this.$router.push({ name: 'home' });
    }
    this.reportData = await this.$services.api.get(this.url);
    this.ready = true;
  }
}
