import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/patients',

    component: () =>
      import('../../../layouts').then((module) => {
        const { AdminLayoutMain } = module;
        return AdminLayoutMain;
      }),
    children: [
      {
        path: '',
        name: 'patients.view',
        component: () =>
          import('../views').then((module) => {
            const { PatientsView } = module;
            return PatientsView;
          }),
      },
    ],
  },
];

export { routes };
