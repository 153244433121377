













































import { User } from '@/models/User';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class LoginForm extends Vue {
  drawer = false;
  valid = false;
  formData = {
    email: '',
    password: '',
  };
  failed = false;
  rules = [(v: string): string | boolean => !!v || 'Field is required'];
  error = '';
  loading = false;

  get loggedIn(): boolean {
    return this.$storage.auth.isAuthed;
  }

  user: User | null = null;

  async login(): Promise<void> {
    // const loginSuccess = await User.login(this.formData);
    // if (loginSuccess) {
    //   this.$router.push({ name: 'dashboard' });
    // }

    this.loading = true;
    const user = await User.login(this.formData);
    this.loading = false;
    this.user = user;
    if (user) {
      this.$storage.auth.login(user);
    }

    this.$router.push({ name: 'admin.dashboard' });
  }

  async mounted(): Promise<void> {
    if (this.loggedIn) {
      this.user = await User.getCurrentUser();
    }
  }
}
