




















import Vue from 'vue';
import Component from 'vue-class-component';

import QuizDivider from './QuizDivider.vue';

@Component({
  components: {
    QuizDivider,
  },
})
export default class QuizWrapperIntro extends Vue {
  startQuiz(): void {
    this.$storage.main.actions.startQuiz();
  }
}
