import { Dashboard, Patients, Questions, Quizzes } from './modules';

export const Admin = {
  modules: {
    Dashboard,
    Patients,
    Questions,
    Quizzes,
  },
};
