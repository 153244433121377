










































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import QuizDivider from './QuizDivider.vue';

@Component({
  components: {
    QuizDivider,
  },
})
export default class QuizQuestion extends Vue {
  @Prop({ required: true, type: Number })
  readonly id!: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  question: null | any = null;

  get progress(): number {
    const count = this.$storage.main.getters.getQuestionCount();
    const question = this.$storage.main.getters.getCurrentQuestion() - 1;
    return 100 * (question / count);
  }

  private get answerWidth(): string {
    return this.$isMobile() || this.$isTablet() ? '' : '130px';
  }

  private get answerHeight(): string {
    return this.$isMobile() || this.$isTablet() ? '' : '100px';
  }

  get isAnswered(): boolean {
    return this.question.answered;
  }

  back(): void {
    if (this.id != 1) {
      this.$storage.main.actions.setCurrentQuestion(this.id - 1);
    }
  }

  isCurrentAnswer(answer: any): boolean {
    return answer.value == this.question.answer?.value;
  }

  nextQuestion(): void {
    if (this.isAnswered) {
      this.$storage.main.actions.setCurrentQuestion(this.id + 1);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  answerQuestion(answer: any): void {
    this.$storage.main.actions.answerQuestion({ id: this.id, answer: answer });
    this.nextQuestion();
  }

  @Watch('id', { immediate: true })
  onQuestionChanged(): void {
    this.question = this.$storage.main.getters.getQuestionById(this.id);
  }
}
