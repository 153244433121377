




































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import Graph from '../components/ReportGraphTest.vue';
import ReportSectionIntro from '../components/ReportSectionIntro.vue';
import ReportItemHeading from '../components/ReportItemHeading.vue';
import ReportSectionSummary from '../components/ReportSectionSummary.vue';
import ReportSectionScale from '../components/ReportSectionScale.vue';

@Component({
  components: {
    Graph,
    ReportSectionIntro,
    ReportItemHeading,
    ReportSectionSummary,
    ReportSectionScale,
  },
})
export default class ReportPage extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) readonly data!: null | any;

  subX = '<sub> horizontal</sub>';
  subY = '<sub> vertical</sub>';

  getScore(pair: string, scale: string): number | string {
    return this.data.report[pair].scales[scale].result.value;
  }

  getLogoUrl(): string {
    return require('@/assets/logo-white.png');
  }

  getTitleFromResults(section: { content: { name: string } }): string {
    return section.content.name;
  }
}
