import { User } from '@/models/User';
import { APIService } from '../api';

export class AuthService {
  constructor(private api: APIService) {}

  async getCsrfCookie(): Promise<void> {
    await this.api.get('/sanctum/csrf-cookie');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async login(credentials: { email: string; password: string }): Promise<any> {
    await this.getCsrfCookie();
    const response = await this.api.post('/login', credentials);
    return new User(response);
  }

  async logout(): Promise<void> {
    const response = await this.api.post('/logout', null);
    return response;
  }
}
