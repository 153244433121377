












/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Component from 'vue-class-component';
import QuizQuestion from './QuizQuestion.vue';

@Component({
  components: {
    QuizQuestion,
  },
})
export default class QuizWrapperQuestions extends Vue {
  get isInDev(): boolean {
    // return true;
    return process.env.NODE_ENV == 'development';
  }

  get currentQuestionId(): any {
    return this.$storage.main.getters.getCurrentQuestion();
  }

  answerAllQuestions(): void {
    const questions = this.$storage.main.getters.getQuestions();

    questions.forEach((question) => {
      this.$storage.main.actions.answerQuestion({
        id: question.id,
        answer: question.answers[Math.floor(Math.random() * 4)],
      });
    });
    this.$storage.main.actions.setCurrentQuestion(questions.length);
  }
}
