import { IGetters } from './getters.types';
import { IState } from '../store.types';

export const getters: IGetters = {
  getStatus(state: IState) {
    return state.status;
  },
  isQuizInProgress(state: IState) {
    return state.status == 'in-progress';
  },
  isStartOfQuiz(state: IState) {
    return state.status == 'not-started';
  },
  isEndOfQuiz(state: IState) {
    return state.status == 'completed';
  },
  getQuestions(state) {
    return state.questions;
  },
  getQuestionByUuid: (state) => (uuid: string) => {
    const question = state.questions.find((question) => question.uuid == uuid);
    return question;
  },
  getQuestionById: (state) => (id: number) => {
    const question = state.questions.find((question) => question.id == id);
    return question;
  },
  getCurrentQuestion(state) {
    return state.currentQuestion;
  },
  getQuestionCount(state) {
    return state.questions.length;
  },
  getQuiz(state) {
    return state;
  },
};
