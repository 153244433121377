

















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component
export default class QuizWrapperEndForm extends Vue {
  name = null;
  email = null;
  valid = false;
  dob = null;
  menu = false;
  loading = false;

  nameRules = [(v: string): string | boolean => !!v || 'Name is required'];

  emailRules = [
    (v: string): string | boolean => !!v || 'E-mail is required',
    (v: string): string | boolean =>
      /.+@.+\..+/.test(v) || 'E-mail must be valid',
  ];

  dobRules = [];

  save(date: string): void {
    (this.$refs.menu as Vue & { save: (date: string) => void }).save(date);
  }

  async completeQuiz(): Promise<void> {
    this.loading = true;
    const response = await this.$services.questions.completeQuiz({
      quiz: this.$storage.main.getters.getQuiz(),
      patient: {
        name: this.name,
        email: this.email,
        dob: this.dob,
      },
    });
    this.loading = false;
    this.$router.push({
      name: 'report',
      params: {
        data: response,
      },
    });
  }

  @Watch('menu')
  onMenuChanged(val: string): void {
    val &&
      setTimeout(
        () =>
          ((this.$refs.picker as Vue & {
            activePicker: string;
          }).activePicker = 'YEAR')
      );
  }
}
