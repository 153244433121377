import { IStorage } from './storage.types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const makeStorage = (store: any): IStorage => ({
  actions: {
    startQuiz() {
      return store.dispatch('main/startQuiz');
    },
    endQuiz() {
      return store.dispatch('main/endQuiz');
    },
    fetchQuestions() {
      return store.dispatch('main/fetchQuestions');
    },
    setCurrentQuestion(id: number) {
      return store.dispatch('main/setCurrentQuestion', id);
    },
    answerQuestion(payload) {
      return store.dispatch('main/answerQuestion', payload);
    },
  },
  mutations: {
    startQuiz() {
      store.commit('main/startQuiz');
    },
    endQuiz() {
      store.commit('main/endQuiz');
    },
    storeQuestionsForQuiz(questions) {
      store.commit('main/storeQuestionsForQuiz', questions);
    },
    setCurrentQuestion(id) {
      store.commit('main/setCurrentQuestion', id);
    },
    answerQuestion(payload) {
      store.commit('main/answerQuestion', payload);
    },
  },
  getters: {
    getStatus() {
      return store.getters['main/getStatus'];
    },
    isStartOfQuiz() {
      return store.getters['main/isStartOfQuiz'];
    },
    isEndOfQuiz() {
      return store.getters['main/isEndOfQuiz'];
    },
    isQuizInProgress() {
      return store.getters['main/isQuizInProgress'];
    },
    getQuestions() {
      return store.getters['main/getQuestions'];
    },
    getCurrentQuestion() {
      return store.getters['main/getCurrentQuestion'];
    },
    getQuestionByUuid(uuid: string) {
      return store.getters['main/getQuestionByUuid'](uuid);
    },
    getQuestionById(id: number) {
      return store.getters['main/getQuestionById'](id);
    },
    getQuestionCount() {
      return store.getters['main/getQuestionCount'];
    },
    getQuiz() {
      return store.getters['main/getQuiz'];
    },
  },
});
