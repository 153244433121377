





























import Vue from 'vue';
import Component from 'vue-class-component';
import { VNavigationDrawer } from 'vuetify/lib';
import { VApp, VAppBar, VContent } from 'vuetify/lib';
import { QuizWrapper } from '../components';

@Component({
  components: {
    VNavigationDrawer,
    VApp,
    VAppBar,
    VContent,
    QuizWrapper,
  },
})
export default class HomePage extends Vue {
  drawer = false;

  private get barHeight(): number {
    return this.$isMobile() ? 60 : 180;
  }
}
