import { render, staticRenderFns } from "./GuestLayoutMain.vue?vue&type=template&id=4c23e60f&scoped=true&"
import script from "./GuestLayoutMain.vue?vue&type=script&lang=ts&"
export * from "./GuestLayoutMain.vue?vue&type=script&lang=ts&"
import style0 from "./GuestLayoutMain.vue?vue&type=style&index=0&id=4c23e60f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c23e60f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VAppBar,VIcon,VImg,VMain,VSpacer})
