<template>
  <div class="chart-wrapper">
    <canvas :id="scale"></canvas>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Chart from 'chart.js/auto';
import hexToRgba from 'hex-to-rgba';
import annotationPlugin from 'chartjs-plugin-annotation';

export default {
  name: 'PlanetChart',

  props: {
    scale: {
      required: true,
    },
    data: {
      required: true,
    },
  },
  data: () => ({
    chart: null,
  }),

  methods: {
    beforePrintHandler() {
      console.log('handled');
      for (var id in Chart.instances) {
        console.log(Chart.instances[id]);
        Chart.instances[id].resize();
      }
    },
  },

  mounted() {
    // window.addEventListener('beforeprint', this.beforePrintHandler);

    const quadrants = {
      id: 'quadrants',
      beforeDraw(chart, args, options) {
        const {
          ctx,
          chartArea: { left, top, right, bottom },
          scales: { x, y },
        } = chart;
        const midX = x.getPixelForValue(x.min + (x.max - x.min) / 2);
        const midY = y.getPixelForValue(y.min + (y.max - y.min) / 2);

        const transparency = 0.9;

        ctx.save();

        // Top left rectangle
        // void ctx.fillRect(x, y, width, height);
        ctx.fillStyle = hexToRgba(options.topLeft, transparency);
        ctx.fillRect(left, top, midX - left, bottom - midY);

        // Top right rectangle
        ctx.fillStyle = hexToRgba(options.topRight, transparency);
        ctx.fillRect(midX, top, right - midX, midY - top);

        // Bottom right rectangle
        ctx.fillStyle = hexToRgba(options.bottomRight, transparency);
        ctx.fillRect(midX, midY, right - midX, bottom - midY);

        // Bottom left rectangle
        ctx.fillStyle = hexToRgba(options.bottomLeft, transparency);
        ctx.fillRect(left, midY, midX - left, bottom - midY);
        ctx.restore();
      },
    };

    const data = {
      type: 'scatter',
      datasets: [
        {
          xScaleID: 'x',
          label: 'Dataset 1',
          data: [
            {
              x: this.data.x.result.value,
              y: this.data.y.result.value,
            },
          ],
          pointStyle: 'circle',
          radius: 12,
          backgroundColor: this.$vuetify.theme.currentTheme.primary,
        },
      ],
    };

    const config = {
      type: 'scatter',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: null,
        },
        onResive: (chart) => {
          chart.update();
        },
        tooltips: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          quadrants: {
            topLeft: this.$vuetify.theme.currentTheme.graphOrange,
            topRight: this.$vuetify.theme.currentTheme.graphRed,
            bottomRight: this.$vuetify.theme.currentTheme.graphYellow,
            bottomLeft: this.$vuetify.theme.currentTheme.graphGreen,
          },
        },
        scales: {
          x: {
            title: {
              display: false,
              text:
                this.data.x.scale.charAt(0).toUpperCase() +
                this.data.x.scale.slice(1),
            },
            min: 0,
            max: this.data.x.result.mid * 2,
            reverse: this.data.x.result.reversed,
            ticks: {
              font: {
                size: 8,
              },
              stepSize:
                this.data.x.result.mid % 3 == 0
                  ? this.data.x.result.mid / 3
                  : this.data.x.result.mid % 4 == 0
                  ? this.data.x.result.mid / 4
                  : this.data.x.result.mid % 5 == 0
                  ? this.data.x.result.mid / 5
                  : 2,
            },
          },
          y: {
            title: {
              display: false,
              text:
                this.data.y.scale.charAt(0).toUpperCase() +
                this.data.y.scale.slice(1),
            },
            position: 'left',
            min: 0,
            max: this.data.y.result.mid * 2,
            reverse: this.data.y.result.reversed,
            ticks: {
              font: {
                size: 8,
              },
              stepSize:
                this.data.y.result.mid % 3 == 0
                  ? this.data.y.result.mid / 3
                  : this.data.y.result.mid % 4 == 0
                  ? this.data.y.result.mid / 4
                  : this.data.y.result.mid % 5 == 0
                  ? this.data.y.result.mid / 5
                  : 2,
            },
          },
        },
      },
      plugins: [quadrants],
    };

    const ctx = document.getElementById(this.scale);
    Chart.register(annotationPlugin);
    const myChart = new Chart(ctx, config);
    window.addEventListener('beforeprint', () => {
      console.log('myChart', myChart);
      myChart.render();
      // myChart.resize(600, 600);
    });
    window.addEventListener('afterprint', () => {
      console.log('myChart', myChart);
      // myChart.resize(600, 600);
    });
  },
};
</script>

<style scoped>
@media screen {
  .chart-wrapper {
    height: 200px;
    position: relative;
  }
}

@media print {
  .chart-wrapper {
    height: 160px;
    position: relative;
    width: 100%;
    min-height: 100%;
    max-width: 320;
    max-height: 100%;
    height: auto !important;
    width: 100% !important;
  }
}
</style>
