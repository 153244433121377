import Vue from 'vue';
import './plugins/composition';
// import './plugins/axios';
import { App } from './app';
import router from './router';
// import store from './state';
import { vuetify } from './plugins';
import { prepareServices, prepareStorage } from './plugins';
import { makeStore } from './store';

Vue.config.productionTip = false;

Vue.prototype.$isMobile = function () {
  return this.$vuetify.breakpoint.name == 'xs';
};

Vue.prototype.$isTablet = function () {
  return this.$vuetify.breakpoint.name == 'sm';
};

export const store = makeStore();
export const services = prepareServices(store);
export const storage = prepareStorage(store, services);

BaseModel.setStorage(storage);
BaseModel.setServices(services);

new Vue({
  router,
  //   store,
  vuetify,
  mixins: [],
  render: (h) => h(App),
}).$mount('#app');
// import plugin

import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css';
// Vuetify's CSS styles
import 'vuetify/dist/vuetify.min.css';
import { BaseModel } from './models/BaseModel';

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
});
