<template>
  <div>
    <!-- Use the component in the right place of the template -->
    <tiptap-vuetify
      v-model="content"
      :extensions="extensions"
      @input="$emit('input', $event)"
      :card-props="{ flat: true }"
    />
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
} from 'tiptap-vuetify';

export default {
  props: {
    initContent: {
      default: '',
    },
  },
  // specify TiptapVuetify component in "components"
  components: { TiptapVuetify },
  data: () => ({
    // declare extensions you want to use
    extensions: [
      // History,
      Blockquote,
      Link,
      Underline,
      // Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      // Code,
      // HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    // starting editor's content
    content: '',
  }),
  mounted() {
    this.content = this.initContent;
  },
};
</script>
<style>
.tiptap-vuetify-editor__content .ProseMirror {
  min-height: 450px;
}
</style>
