import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/',

    component: () =>
      import('../../layouts').then((module) => {
        const { GuestLayoutMain } = module;
        return GuestLayoutMain;
      }),
    meta: {
      requireAuth: false,
      domain: 'guest',
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () =>
          import('../views').then((module) => {
            const { HomePage } = module;
            return HomePage;
          }),
        meta: {
          requireAuth: false,
          domain: 'guest',
        },
      },
      {
        path: '/login',
        name: 'login',
        component: () =>
          import('../views').then((module) => {
            const { LoginPage } = module;
            return LoginPage;
          }),
        meta: {
          requireAuth: false,
          domain: 'guest',
        },
      },
      {
        path: '/report',
        name: 'report',
        component: () =>
          import('../views').then((module) => {
            const { ReportPage } = module;
            return ReportPage;
          }),
        props: true,
        meta: {
          requireAuth: false,
          domain: 'guest',
        },
      },
      {
        path: '/report-renderer',
        name: 'report-renderer',
        component: () =>
          import('../views').then((module) => {
            const { ReportRenderer } = module;
            return ReportRenderer;
          }),
        props: (route) => ({ url: route.query.url }),
        meta: {
          requireAuth: false,
          domain: 'guest',
        },
      },
    ],
  },
];

export { routes };
