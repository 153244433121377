/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMutations } from './mutations.types';

export const mutations: IMutations = {
  startQuiz(state) {
    state.status = 'in-progress';
  },
  endQuiz(state) {
    state.status = 'completed';
  },
  storeQuestionsForQuiz(state, questions: any): void {
    state.questions = questions;
  },
  setCurrentQuestion(state, id): void {
    state.currentQuestion = id;
  },
  answerQuestion(state, { id, answer }): void {
    const question = state.questions.find((question) => question.id == id);
    question.answered = true;
    question.answer = answer;
  },
};
