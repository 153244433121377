import { IState } from './store.types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
export * from './storage';

const state: IState = {
  status: 'not-started',
  questions: [],
  currentQuestion: null,
};

export const Store = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
