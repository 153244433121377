




import { Vue, Component, Prop } from 'vue-property-decorator';
import { data } from './data';
import Graph from '../components/ReportGraphTest.vue';
import Report from '../components/Report.vue';

@Component({
  components: {
    Graph,
    Report,
  },
})
export default class ReportPage extends Vue {
  @Prop({ default: null }) readonly data: any;

  reportData: any = null;
  ready = false;

  async mounted(): Promise<void> {
    this.reportData = this.data;
    if (process.env.NODE_ENV == 'development') {
      this.reportData = await this.$services.quizzes.getCompletedQuiz(1);
    }
    if (this.reportData == null) {
      this.$router.push({ name: 'home' });
    }
    this.ready = true;
  }
}
