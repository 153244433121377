






















































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import QuizTextEditor from '../components/QuizTextEditor.vue';
import { debounce } from 'vue-debounce';

@Component({
  components: {
    QuizTextEditor,
  },
})
export default class QuizViewSingle extends Vue {
  @Prop({ required: true, type: [Number, String] }) readonly id!:
    | number
    | string;

  quiz: any = null;
  selectedSection: any = null;
  saving = false;

  async mounted(): Promise<void> {
    let response = await this.$services.quizzes.getQuizById(this.id as number);
    const sortedConfig = response.config.map((section: any) => {
      const sortingArr = ['intro', 'main', 'recommendation'];
      let quizArray = [];
      for (var key in section.sections) {
        quizArray.push([key, section.sections[key]]);
      }
      quizArray.sort(function (a, b) {
        return sortingArr.indexOf(a[0]) - sortingArr.indexOf(b[0]);
      });
      var objSorted: any = {};
      quizArray.forEach(function (item) {
        objSorted[item[0]] = item[1];
      });

      section.sections = objSorted;

      return section;
    });

    response.config = sortedConfig;

    this.quiz = response;
  }

  updateSection(key: any, input: any): void {
    let section = this.quiz.config.find((obj: any) => {
      return obj.key == this.selectedSection.key;
    });
    section.sections[key] = input;
    this.saveSection();
  }

  saveSection = debounce(() => this.saveQuiz(), '500ms');

  async saveQuiz(): Promise<void> {
    this.saving = true;
    await this.$services.quizzes.updateQuiz(this.quiz);
    setTimeout(() => {
      this.saving = false;
    }, 500);
  }
}
