










import Vue from 'vue';
import Component from 'vue-class-component';
import QuizWrapperIntro from './QuizWrapperIntro.vue';
import QuizWrapperQuestions from './QuizWrapperQuestions.vue';
import QuizWrapperEnd from './QuizWrapperEnd.vue';

@Component({
  components: {
    QuizWrapperIntro,
    QuizWrapperQuestions,
    QuizWrapperEnd,
  },

  mounted() {
    this.$services.questions.getQuestions();
    this.$storage.main.actions.fetchQuestions();
  },
})
export default class QuizWrapper extends Vue {
  private get isStartOfQuiz(): boolean {
    return this.$storage.main.getters.isStartOfQuiz();
  }
}
