/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { Store as MainStore } from './quiz';

Vue.use(Vuex);

export const makeStore = (): Store<any> =>
  new Vuex.Store<any>({
    modules: {
      main: MainStore,
    },
  });
