


























/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Component from 'vue-class-component';
import QuizQuestion from './QuizQuestion.vue';
import QuizDivider from './QuizDivider.vue';
import QuizWrapperEndForm from './QuizWrapperEndForm.vue';

@Component({
  components: {
    QuizQuestion,
    QuizDivider,
    QuizWrapperEndForm,
  },
})
export default class QuizWrapperQuestions extends Vue {
  answerAllQuestions(): void {
    const questions = this.$storage.main.getters.getQuestions();

    questions.forEach((question) => {
      this.$storage.main.actions.answerQuestion({
        id: question.id,
        answer: question.answers[0],
      });
    });
    this.$storage.main.actions.setCurrentQuestion(questions.length);
  }
}
