import {
  AuthService,
  QuestionsService,
  QuizzesService,
  APIService,
} from '../services';
import { IServiceProvider } from './provider.types';

export const provider = (): IServiceProvider => {
  const api = new APIService();
  return {
    auth: new AuthService(api),
    api,
    questions: new QuestionsService(api),
    quizzes: new QuizzesService(api),
  };
};
