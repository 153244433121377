import { IActions, Context } from './actions.types';

export const actions: IActions = {
  startQuiz() {
    this.$storage.main.actions.setCurrentQuestion(1);
    this.$storage.main.mutations.startQuiz();
  },
  endQuiz() {
    this.$storage.main.mutations.endQuiz();
    this.$storage.main.actions.setCurrentQuestion(null);
  },
  async fetchQuestions() {
    const questions = await this.$services.questions.getQuestionsForQuiz();
    this.$storage.main.mutations.storeQuestionsForQuiz(questions);
  },
  setCurrentQuestion(context: Context, id) {
    const totalQuestions = this.$storage.main.getters.getQuestionCount();
    if (id > totalQuestions && this.$storage.main.getters.isQuizInProgress()) {
      this.$storage.main.mutations.setCurrentQuestion(null);
      this.$storage.main.actions.endQuiz();
    } else {
      this.$storage.main.mutations.setCurrentQuestion(id);
    }
  },
  answerQuestion(context: Context, { id, answer }) {
    this.$storage.main.mutations.answerQuestion({ id, answer });
  },
};
