<template>
  <div>
    <canvas id="planet-chart"></canvas>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Chart from 'chart.js/auto';
import hexToRgba from 'hex-to-rgba';

export default {
  name: 'PlanetChart',

  mounted() {
    const quadrants = {
      id: 'quadrants',
      beforeDraw(chart, args, options) {
        const {
          ctx,
          chartArea: { left, top, right, bottom },
          scales: { x, y },
        } = chart;
        const midX = x.getPixelForValue(x.min + (x.max - x.min) / 2);
        const midY = y.getPixelForValue(y.min + (y.max - y.min) / 2);

        const transparency = 0.9;

        ctx.save();

        // Top left rectangle
        // void ctx.fillRect(x, y, width, height);
        ctx.fillStyle = hexToRgba(options.topLeft, transparency);
        ctx.fillRect(left, top, midX - left, bottom - midY);

        // Top right rectangle
        ctx.fillStyle = hexToRgba(options.topRight, transparency);
        ctx.fillRect(midX, top, right - midX, midY - top);

        // Bottom right rectangle
        ctx.fillStyle = hexToRgba(options.bottomRight, transparency);
        ctx.fillRect(midX, midY, right - midX, bottom - midY);

        // Bottom left rectangle
        ctx.fillStyle = hexToRgba(options.bottomLeft, transparency);
        ctx.fillRect(left, midY, midX - left, bottom - midY);
        ctx.restore();
      },
    };

    const data = {
      type: 'scatter',
      datasets: [
        {
          xScaleID: 'x',
          label: 'Dataset 1',
          data: [
            {
              x: 20,
              y: 40,
            },
          ],
          pointStyle: 'circle',
          radius: 20,
          backgroundColor: this.$vuetify.theme.currentTheme.primary,
        },
      ],
    };

    const config = {
      type: 'scatter',
      data: data,
      options: {
        hover: {
          mode: null,
        },
        tooltips: false,
        plugins: {
          tooltip: {
            enabled: false,
          },
          quadrants: {
            topLeft: this.$vuetify.theme.currentTheme.orange,
            topRight: this.$vuetify.theme.currentTheme.red,
            bottomRight: this.$vuetify.theme.currentTheme.yellow,
            bottomLeft: this.$vuetify.theme.currentTheme.green,
          },
        },
        scales: {
          x: {
            min: 10,
            max: 40,
          },
          y: {
            position: 'left',
            min: 10,
            max: 50,
          },
        },
      },
      plugins: [quadrants],
    };

    const ctx = document.getElementById('planet-chart');
    new Chart(ctx, config);
  },
};
</script>
