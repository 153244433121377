import { APIService, IServiceProvider } from '@/services';
import { Builder } from './Builder';
import { IStorage as IStorageProvider } from '@/store';

export interface BaseModelData {
  uuid: string;
}

export type ModelRelations = {
  [key in keyof Partial<BaseModelData>]: any;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface MetaBaseModel<M, D> {
  new (): M;
  builder<M extends BaseModel, D = NonNullable<M['data']>>(
    this: MetaBaseModel<M, D>
  ): Builder<M, D>;
}

export class BaseModel<ModelData = BaseModelData> {
  constructor(public data: ModelData = {} as ModelData) {}

  static storageProvider: IStorageProvider;
  static serviceProvider: IServiceProvider;

  static get $api(): APIService {
    return BaseModel.serviceProvider.api;
  }

  static setStorage(storageProvider: IStorageProvider): void {
    BaseModel.storageProvider = storageProvider;
  }

  static setServices(serviceProvider: IServiceProvider): void {
    BaseModel.serviceProvider = serviceProvider;
  }

  // static page<M extends BaseModel>(this: MetaBaseModel<M>, pageNumber: number): Builder<M> {
  //   const builder = this.builder();
  //   return builder.page(pageNumber);
  // }

  static builder<M extends BaseModel, D = M['data']>(
    this: MetaBaseModel<M, D>
  ): Builder<M, D> {
    return new Builder(new this(), this, BaseModel.$api);
  }

  static async get<M extends BaseModel, D = M['data']>(
    this: MetaBaseModel<M, D>
  ): Promise<M[]> {
    const builder = this.builder();
    return await builder.get();
  }

  get slug(): string {
    return (this.constructor.name + 's').toLowerCase();
  }
}
