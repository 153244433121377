import { axios } from '@/plugins';
import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import router from '../../router';

// const api = function (
//   method: 'get' | 'post' | 'put' | 'delete',
//   url: string,
//   data: any = null
// ): Promise<any> {
//   return axios
//     .request({ url, method, data })
//     .then((response: AxiosResponse) => {
//       return response.data;
//     })
//     .catch((error: AxiosError) => {
//       if (
//         error.response &&
//         error.response.status == 401 &&
//         router.currentRoute.name != 'login' &&
//         router.currentRoute.meta?.requireAuth != false
//       ) {
//         router.push({ name: 'login' });
//       }
//       return Promise.reject(error);
//     })
//     .finally();
// };

// export { api };

type APIRequestConfig = AxiosRequestConfig;

type APIResponse<T = any> = Promise<T>;

export class APIService {
  constructor(
    public defaultBaseUrl: string | undefined = axios.defaults.baseURL,
    public providerUrl: string | null = ''
  ) {}

  baseUrl(options?: APIRequestConfig): string {
    const url = this.defaultBaseUrl;

    return url ? url : '';
  }

  config(options?: APIRequestConfig): AxiosRequestConfig {
    return {
      baseURL: this.baseUrl(options),
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get(url: string, options?: APIRequestConfig): APIResponse {
    const response = await axios.get(url, this.config(options));
    return response.data;
  }

  async post<T = any>(
    url: string,
    data: any = null,
    options?: APIRequestConfig
  ): APIResponse<T> {
    const response = await axios.post(url, data, this.config(options));
    return response.data;
  }

  async put(url: string, data: { [key: string]: any }): APIResponse {
    const response = await axios.put(url, data);
    return response.data;
  }
}
