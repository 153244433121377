import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/questions',

    component: () =>
      import('../../../layouts').then((module) => {
        const { AdminLayoutMain } = module;
        return AdminLayoutMain;
      }),
    children: [
      {
        path: '',
        name: 'questions.view',
        component: () =>
          import('../views').then((module) => {
            const { QuestionsView } = module;
            return QuestionsView;
          }),
      },
    ],
  },
];

export { routes };
