import { APIService } from '@/services';
import { BaseModel, BaseModelData } from './BaseModel';

export class Builder<M extends BaseModel, ModelData = BaseModelData> {
  selected?: keyof ModelData;
  pageNumber?: number;

  constructor(
    public model: M,
    public modelConstructor: new (data: ModelData) => M,
    public $api: APIService
  ) {
    this.model = model;
    this.modelConstructor = modelConstructor;
  }

  page(page: number): this {
    this.pageNumber = page;
    return this;
  }

  select(col: keyof ModelData): this {
    this.selected = col;
    return this;
  }

  async get(): Promise<M[]> {
    const slug = this.model.slug;
    const page = this.pageNumber;

    const query = page ? `/${slug}?page=${page}` : `/${slug}`;
    const response = await this.$api.get(query);

    return response.map((data: ModelData) => new this.modelConstructor(data));
  }
}
