/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIService } from '../api';
import { IQuizzesService } from './quizService.types';

export class QuizzesService implements IQuizzesService {
  constructor(private api: APIService) {}
  async getQuizzes(): Promise<any> {
    const response = await this.api.get('/quizzes');
    return response;
  }

  async getQuizById(id: number): Promise<any> {
    const response = await this.api.get(`/quizzes/${id}`);
    return response;
  }

  async updateQuiz(quiz: any): Promise<any> {
    const response = await this.api.put(`/quizzes/${quiz.id}`, quiz);
    return response;
  }

  async getCompletedQuiz(id: number | string): Promise<any> {
    const response = await this.api.get(`/completed-quizzes/${id}`);
    return response;
  }
}
