








import Vue from 'vue';
import Component from 'vue-class-component';
import QuestionsTable from '../components/QuestionsTable.vue';

@Component({
  components: {
    QuestionsTable,
  },
})
export default class QuestionsView extends Vue {}
