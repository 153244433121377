import { User } from '@/models/User';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {},
  render(this: Dashboard) {
    return <div>Dashboard</div>;
  },
})
export class Dashboard extends Vue {
  drawer = false;
  valid = false;
  formData = {
    email: '',
    password: '',
  };
  failed = false;
  rules = [(v: string): string | boolean => !!v || 'Field is required'];
  error = '';

  login(): void {
    User.login(this.formData);
  }
}
