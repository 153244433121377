


























import Vue from 'vue';
import Component from 'vue-class-component';
import ReportSectionScaleGraphChart from '../components/ReportSectionScaleGraphChart.vue';
import { Prop } from 'vue-property-decorator';

interface Data {
  [key: string]: {
    result: {
      value: string | number;
      axis: string;
    };
  };
}

@Component({
  components: {
    ReportSectionScaleGraphChart,
  },
})
export default class ReportSectionScaleGraph extends Vue {
  @Prop({ required: true }) readonly data!: Data;
  sheet = {
    height: 15,
  };

  get chartData(): { x: any; y: any } {
    let data = {
      x: null,
      y: null,
    } as {
      x: any;
      y: any;
    };

    for (const [, value] of Object.entries(this.data)) {
      if (value.result.axis == 'horizontal') {
        data.x = value;
      } else if (value.result.axis == 'vertical') {
        data.y = value;
      }
    }
    return data;
  }

  quadrants = [
    {
      text: 'Not at risk',
      colour: 'graphGreen',
    },

    {
      text: `Help with <br> ${this.chartData.y.scale} advised`,
      colour: 'graphOrange',
    },
    {
      text: `Help with <br> ${this.chartData.x.scale} advised`,
      colour: 'graphYellow',
    },
    {
      text: `Help with <br> ${this.chartData.y.scale} and ${this.chartData.x.scale} advised`,
      colour: 'graphRed',
    },
  ];
}
