








































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { format, parseISO } from 'date-fns';

@Component
export default class ReportSectionIntro extends Vue {
  @Prop({ required: true }) readonly data!: any;

  get reportDate(): any {
    const date = parseISO(this.data.details.completed);
    return format(date, 'do MMM yyyy');
  }

  get dateOfBirth(): string {
    try {
      let date = parseISO(this.data.patient.dob);
      return format(date, 'do MMM yyyy');
    } catch (e) {
      return 'Not supplied';
    }
  }
}
