import { DeepReadonly, reactive, readonly } from '@/plugins/composition';
import { IServiceProvider } from '@/services';
import { User } from '@/models/User';

export interface IAuthState {
  authenticated: boolean;
  user: User | null;
  provider: string;
}

const initialState = {
  user: null,
  authenticated: false,
  provider: '',
};

export class AuthStorage {
  constructor(
    public $services: IServiceProvider,
    public store: IAuthState = reactive({ ...initialState })
  ) {}

  getLocalAuth(): IAuthState | null {
    const storedAuth = window.localStorage.getItem('auth');
    return storedAuth ? JSON.parse(storedAuth) : null;
  }

  setLocalAuth(state: IAuthState): void {
    window.localStorage.setItem('auth', JSON.stringify(state));
  }

  clearLocalAuth(): void {
    window.localStorage.clear();
  }

  get user(): User | null {
    return this.store.user;
  }

  get state(): DeepReadonly<IAuthState> {
    return readonly(this.store);
  }

  get isAuthed(): boolean {
    return this.state.authenticated;
  }

  get provider(): string | null {
    return this.state.provider;
  }

  get auth(): DeepReadonly<IAuthState> {
    return this.state;
  }

  login(user: User): boolean {
    this.store.user = user;
    this.store.authenticated = true;
    this.setLocalAuth(this.store);
    return true;
  }

  async checkAuth(): Promise<User | null> {
    let user: User | null = null;
    const storedUser = this.getLocalAuth();
    if (storedUser) {
      try {
        user = await User.getCurrentUser();
        this.login(user);
      } catch (e) {
        this.clearLocalAuth();
      }
    }
    return user;
  }

  logout(): void {
    this.clearLocalAuth();
    Object.assign(this.state, initialState);
  }
}
