





















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class QuizzesTable extends Vue {
  pagination = {};
  headers = [
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  quizzes: any[] = [];
  editing = false;
  editingQuestion = null;

  async mounted(): Promise<void> {
    this.loadquizzes();
  }

  async loadquizzes(): Promise<void> {
    this.quizzes = await this.$services.quizzes.getQuizzes();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  edit(item: any): void {
    this.editing = true;
    this.editingQuestion = item;
  }
}
