


















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import QuestionEditDialog from './QuestionEditDialog.vue';

@Component({
  components: {
    QuestionEditDialog,
  },
})
export default class QuestionsTable extends Vue {
  pagination = {};
  headers = [
    {
      text: 'Prefix',
      value: 'prefix',
    },
    {
      text: 'Test',
      value: 'label',
    },
    {
      text: 'Scale',
      value: 'scale',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questions: any[] = [];
  editing = false;
  editingQuestion = null;

  async mounted(): Promise<void> {
    this.loadQuestions();
  }

  async loadQuestions(): Promise<void> {
    this.questions = await this.$services.questions.getQuestions();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  edit(item: any): void {
    this.editing = true;
    this.editingQuestion = item;
  }
}
