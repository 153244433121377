














































import Vue from 'vue';
import Component from 'vue-class-component';
import { menuItems } from './menuItems';

@Component({})
export default class LayoutMain extends Vue {
  private menuItems = menuItems;
  getLogoUrl(): string {
    return require('@/assets/logo-blue.png');
  }
  login(): void {
    this.$router.push({ name: 'login' });
  }
}
