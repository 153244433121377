/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IQuestionsService } from './questionService.types';
import { APIService } from '../api';

export class QuestionsService implements IQuestionsService {
  constructor(private api: APIService) {}
  async getQuestions(): Promise<any> {
    const response = await this.api.get('/quiz/qr-code-test/questions');
    return response;
  }

  async getQuestionsForQuiz(): Promise<any> {
    const questions = await this.getQuestions();
    questions.forEach((question: any) => {
      question.answered = false;
      question.answer = null;
    });
    return questions;
  }

  async completeQuiz(data: any): Promise<any> {
    const response = await this.api.post(
      '/completed-quizzes/qr-code-test',
      data
    );
    return response;
  }

  async updateQuestion(question: any): Promise<any> {
    const response = await this.api.put(`/questions/${question.id}`, question);
    return response;
  }
}
