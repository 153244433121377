import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

const brandColours = {
  blue: '#045b8e',
  lightBlue: '#91aab7',
  orange: '#d14d2a',
  warmGrey: '#d7d1d1',
  warmGrey50: '#f0f0f0',
  red: '#D40135',
  yellow: '#FF9B00',
  green: '#90C509',
};

const colours = {
  ...brandColours,
  primary: brandColours.blue,
  secondary: brandColours.orange,
  accent: brandColours.orange,
  grey: brandColours.warmGrey,
  lightGrey: brandColours.warmGrey50,
  error: brandColours.red,
  info: '#2196F3',
  success: brandColours.green,
  warning: brandColours.orange,
  red: brandColours.red,
  yellow: brandColours.yellow,
  graphRed: '#DC9185',
  graphOrange: '#f9c188',
  graphYellow: '#FFE1A9',
  graphGreen: '#d5e6a0',
};

Vue.use(Vuetify);

export const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        ...colours,
      },
    },
  },
});
