




























import { Vue, Component, Prop } from 'vue-property-decorator';
import ReportSectionScaleGraph from '../components/ReportSectionScaleGraph.vue';

@Component({
  components: {
    ReportSectionScaleGraph,
  },
})
export default class ReportSectionScale extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) readonly data!: any;
}
