/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { makeStorage as makeMainStorage } from '../store/quiz';
import { AuthStorage } from '@/store/auth/authStorage';
import { IServiceProvider } from '@/services';
import { IStorage } from '@/store';

export const prepareStorage = (
  store: any,
  services: IServiceProvider
): IStorage => {
  store.$storage = {
    auth: new AuthStorage(services),
    main: makeMainStorage(store),
  };

  Vue.mixin({
    beforeCreate() {
      this.$storage = store.$storage;
    },
  });

  return store.$storage;
};
