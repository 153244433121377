import { AuthService, LoginCredentials } from '@/services';
import { AuthStorage } from '@/store/auth';
import { BaseModel } from '../BaseModel';

export type UserData = {
  readonly email: string;
  readonly name: string;
  readonly role: string;
};

export type ModelRelations = {
  [key in keyof Partial<UserData>]: any;
};

export class User extends BaseModel<UserData> {
  static get $service(): AuthService {
    return BaseModel.serviceProvider.auth;
  }

  static get $storage(): AuthStorage {
    return BaseModel.storageProvider.auth;
  }

  static async login(credentials: LoginCredentials): Promise<User> {
    const response = await User.$service.login(credentials);
    return response;
  }

  static async logout(): Promise<void> {
    const response = await User.$service.logout();
    User.$storage.logout();
    return response;
  }

  static async getCurrentUser(): Promise<User> {
    const response = await BaseModel.$api.get('/user');
    return new User(response);
  }

  get uuid(): string {
    return this.uuid;
  }
}
