import { User } from '@/models/User';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Guest, Admin } from '../domains';
import { storage } from '../main';

export declare class Router extends VueRouter {
  goHome(): void;
}

const routes: Array<RouteConfig> = [
  ...Guest.modules.Main.Routes.routes,
  ...Admin.modules.Dashboard.Routes.routes,
  ...Admin.modules.Patients.Routes.routes,
  ...Admin.modules.Questions.Routes.routes,
  ...Admin.modules.Quizzes.Routes.routes,
  {
    path: '*',
    redirect: (to) => {
      return { name: 'home' };
    },
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
}) as Router;

router.goHome = () => {
  router.push({ name: 'home' });
};

router.beforeEach(async (to, from, next) => {
  // Check and set the auth
  await storage.auth.checkAuth();

  const requiresAuth = to.matched.some(
    (route) => route.meta.requireAuth != false
  );

  // Get the authed state
  const authed = storage.auth.isAuthed;

  // Doesn't required auth. Not authed.
  // Redirect to login so user can auth
  if (requiresAuth && !authed) {
    next({ name: 'login' });
  }

  // Requires auth. Not authed.
  // Continue as page does not need auth
  if (requiresAuth == false && !authed) {
    next();
  }

  // Required auth. Authed.
  // Check role
  if (requiresAuth && authed) {
    const user = storage.auth.user as User;
    if (to.meta.role && user.data.role == to.meta.role) {
      // route role matches user role so continue
      next();
    } else {
      User.logout();
      next({ name: 'login' });
    }
  }

  next();
});

export default router;
